import React, { useEffect } from 'react';
import { bool, func, node, number, string } from 'prop-types';
import classNames from 'classnames';

import { FormattedMessage } from '../../../util/reactIntl';

import css from './MainPanelHeader.module.css';

const MainPanelHeader = props => {
  const {
    rootClassName,
    className,
    children,
    sortByComponent,
    isSortByActive,
    listingsAreLoaded,
    resultsCount,
    searchInProgress,
    noResultsInfo,
    toggled,
    toggledMap
  } = props;

  const classes = classNames(rootClassName || css.root, className);

  useEffect(() => {
    const mapboxMap = window?.mapboxgl?.Map;
    if (mapboxMap && typeof mapboxMap.resize === 'function') {
      mapboxMap.resize();
    }
  }, [toggled]);

  return (
    <div className={classes}>
      <div className={css.searchOptions}>
        <div className={css.searchResultSummary}>
          <span className={css.resultsFound}>
            {searchInProgress ? (
              <FormattedMessage id="MainPanelHeader.loadingResults" />
            ) : (
              <FormattedMessage
                id="MainPanelHeader.foundResults"
                values={{ count: resultsCount }}
              />
            )}
          </span>
        </div>
        {isSortByActive ? (
          <div className={css.sortyByWrapper}>
            <span className={css.sortyBy}>
              <FormattedMessage id="MainPanelHeader.sortBy" />
            </span>
            {sortByComponent}
          </div>
        ) : null}
        {toggled
          ? <div className={css.toggleSwitch} onClick={toggledMap}>
            <svg height="46" width="46" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1.84 1.84">
              <path d="M1.323 0.46H0.517a0.46 0.46 0 0 0 0 0.92h0.806a0.46 0.46 0 0 0 0 -0.92m-0.806 0.747C0.357 1.207 0.23 1.08 0.23 0.92S0.357 0.633 0.517 0.633 0.804 0.76 0.804 0.92s-0.128 0.287 -0.287 0.287" />
            </svg>
            Show map
          </div>
          : <div className={css.toggleSwitch} onClick={toggledMap}>
            <svg width="47px" height="47px" viewBox="0 0 47 47" xmlns="http://www.w3.org/2000/svg">
              <path d="M33.781 10.281H13.219C5.93 10.281 0 16.211 0 23.5s5.93 13.219 13.219 13.219h20.563c7.289 0 13.219 -5.93 13.219 -13.219s-5.93 -13.219 -13.219 -13.219m0 23.5a10.281 10.281 0 1 1 10.281 -10.281 10.292 10.292 0 0 1 -10.281 10.281" />
            </svg>
            Hide map
          </div>}

      </div>

      {children}

      {noResultsInfo ? noResultsInfo : null}
    </div>
  );
};

MainPanelHeader.defaultProps = {
  rootClassName: null,
  className: null,
  resultsCount: null,
  searchInProgress: false,
  sortByComponent: null,
};

MainPanelHeader.propTypes = {
  rootClassName: string,
  className: string,
  listingsAreLoaded: bool.isRequired,
  resultsCount: number,
  searchInProgress: bool,
  sortByComponent: node,
};

export default MainPanelHeader;
